<template>
  <div>
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Delivery Time Report"/>
        <v-spacer></v-spacer>
        <!-- <v-btn class="mx-2" depressed dark color="primary" @click="()=>$router.push('/approve_drivers/edit')">
          <v-icon left dark>mdi-plus-circle</v-icon> Add
        </v-btn> -->
      </v-toolbar> 
    <v-divider></v-divider>
    <div class="content-wrap">
        <div class="d-flex header_container">

          <div style="min-width:400px; max-width:100%; margin-right:16px; margin-bottom:16px;">
            <div v-if="userProfile.total_managed_stores" class="grid-container dltime_summary" style="margin-bottom: 10px;">
            <div class="item_header text-right">Store</div>
            <div class="item_header text-right">{{userProfile.managed_store_name ? userProfile.managed_store_name : userProfile.total_managed_stores || '' }}</div>
            </div>
            <div class="grid-container dltime_summary">
              <div class="item_header text-left">Summary</div>
              <div class="item_header text-right">Docket</div>
              <div class="item_header text-right">%</div>  
              <div class="text-left">Total Order</div>
              <div class="item_number"><div>{{ (loadingDltimeSummary || tripsTimingSummary == null) ? "..." : tripsTimingSummary.total_docket.toLocaleString() }}</div></div>
               <div class=""></div>
               <div class="text-left">Order ASAP</div>
               <div class="item_number"><div>{{ (loadingDltimeSummary || tripsTimingSummary == null) ? "..." : tripsTimingSummary.asap_docket.toLocaleString() }}</div></div>
               <div class="item_number"><div>{{ (loadingDltimeSummary || tripsTimingSummary == null || tripsTimingSummary.total_docket == 0) ? "..." : (tripsTimingSummary.asap_docket * 100.0 / tripsTimingSummary.total_docket).toFixed(2) + "%" }}</div></div>  
               <div class="text-left">Order Future</div>
               <div class="item_number"><div>{{ (loadingDltimeSummary || tripsTimingSummary == null) ? "..." : tripsTimingSummary.future_docket.toLocaleString() }}</div></div>
               <div class="item_number"><div>{{ (loadingDltimeSummary || tripsTimingSummary == null || tripsTimingSummary.total_docket == 0) ? "..." : (tripsTimingSummary.future_docket * 100.0 / tripsTimingSummary.total_docket).toFixed(2) + "%" }}</div></div>  
               <div class="text-left">Instore time (12 Mins)</div>
               <div class="item_number"><div>{{ (loadingDltimeSummary || tripsTimingSummary == null) ? "..." : tripsTimingSummary.instore_within_12_mins.toLocaleString() }}</div></div>
               <div class="item_number"><div>{{ (loadingDltimeSummary || tripsTimingSummary == null || tripsTimingSummary.total_docket == 0) ? "..." : (tripsTimingSummary.instore_within_12_mins * 100.0 / tripsTimingSummary.total_docket).toFixed(2) + "%" }}</div></div>  
               <div class="text-left">Delivery time (20 Mins)</div>
               <div class="item_number"><div>{{ (loadingDltimeSummary || tripsTimingSummary == null) ? "..." : tripsTimingSummary.delivery_within_20_mins.toLocaleString() }}</div></div>
               <div class="item_number"><div>{{ (loadingDltimeSummary || tripsTimingSummary == null || tripsTimingSummary.total_docket == 0) ? "..." : (tripsTimingSummary.delivery_within_20_mins * 100.0 / tripsTimingSummary.total_docket).toFixed(2) + "%" }}</div></div> 
               <div class="text-left">Delivery time (30 Mins)</div>
               <div class="item_number"><div>{{ (loadingDltimeSummary || tripsTimingSummary == null) ? "..." : tripsTimingSummary.delivery_within_30_mins.toLocaleString() }}</div></div>
               <div class="item_number"><div>{{ (loadingDltimeSummary || tripsTimingSummary == null || tripsTimingSummary.total_docket == 0) ? "..." : (tripsTimingSummary.delivery_within_30_mins * 100.0 / tripsTimingSummary.total_docket).toFixed(2) + "%" }}</div></div>  
               <div class="text-left">Coupon Out (20 Mins)</div>
               <div class="item_number"><div>{{ (loadingDltimeSummary || tripsTimingSummary == null) ? "..." : tripsTimingSummary.coupon_out_pz_20_mins.toLocaleString() }}</div></div>
               <div class="item_number"><div>{{ (loadingDltimeSummary || tripsTimingSummary == null || tripsTimingSummary.total_pz_docket == 0) ? "..." : (tripsTimingSummary.coupon_out_pz_20_mins * 100.0 / tripsTimingSummary.total_pz_docket).toFixed(2) + "%" }}</div></div>  
               <div class="text-left">Coupon Out (30 Mins)</div>
               <div class="item_number"><div>{{ (loadingDltimeSummary || tripsTimingSummary == null) ? "..." : tripsTimingSummary.coupon_out_pz_30_mins.toLocaleString() }}</div></div>
               <div class="item_number"><div>{{ (loadingDltimeSummary || tripsTimingSummary == null || tripsTimingSummary.total_pz_docket == 0) ? "..." : (tripsTimingSummary.coupon_out_pz_30_mins * 100.0 / tripsTimingSummary.total_pz_docket).toFixed(2) + "%" }}</div></div>  
               
            </div>
          </div>
          <v-toolbar class="search-tools pa-0" flat height="300">
            <!-- Action Button -->
            <!-- <v-spacer></v-spacer> -->
            <v-row >              
              <v-col class="d-flex pl-0 pt-0 pb-0" cols="4" sm="4">
                  <v-text-field
                  label="Order No"
                  v-model="options.filter.order_no"
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  class=""
                  clearable
                  @keyup.native.enter="getDataFromApi"
                  /> 
              </v-col>
              <v-col class="d-flex pl-0 pt-0 pb-0" cols="4" sm="4">

                  <v-select
                  v-model="options.filter.brand_id"
                  :items="rb_brands"
                  item-text="shortname"
                  item-value="val"
                  label="Order Brand"
                  multiple
                  :single-line="true"
                  height="32px"
                  >
                      <template v-slot:prepend-item>
                          <v-list-item
                          ripple
                          @click="toggle_multi_select_brand"
                          >
                          <v-list-item-action>
                              <v-icon :color="options.filter.brand_id.length > 0 ? 'indigo darken-4' : ''">{{ selectall_brand_icon }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                              <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                      </template>
                  </v-select>

              </v-col>
             
              <v-col class="d-flex pl-0 pt-0 pb-0" cols="4" sm="4">
                  <v-text-field
                  label="Trip No"
                  v-model="options.filter.trip_name"
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  class=""
                  clearable
                  @keyup.native.enter="getDataFromApi"
                  /> 
              </v-col>

              <v-col class="d-flex pl-0 pt-0 pb-0" cols="4" sm="4">
                <v-select
                    v-model="options.filter.ln_status"
                    :items="lnHookStatus"
                    item-text="value"
                    item-value="value"
                    label="Order Status"
                    multiple
                    :single-line="true"
                    height="32px"
                    >
                    <template v-slot:prepend-item>
                        <v-list-item
                        ripple
                        @click="toggle_multi_select"
                        >
                        <v-list-item-action>
                            <v-icon :color="options.filter.ln_status.length > 0 ? 'indigo darken-4' : ''">{{ selectall_icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
              </v-col>

              <v-col class="d-flex pl-0 pt-0 pb-0" cols="4" sm="4">
                <v-autocomplete
                label="Order Store"
                v-model="options.filter.order_store_uid"
                :items="rb_store_array_filtered"
                item-text="store_alias_id"
                item-value="store_uid"
                multiple
                small-chips
                clearable
                deletable-chips
                prepend-inner-icon="mdi-magnify"
                @blur="sortOrderStoresBySelectedUid"
                /> 
            </v-col>

              <v-col class="d-flex pl-0 pt-0 pb-0" cols="4" sm="4">
                <v-autocomplete
                label="Rider Store"
                v-model="options.filter.employee_store_uid"
                :items="rb_store_array"
                item-text="store_alias_id"
                item-value="store_uid"
                multiple
                small-chips
                clearable
                deletable-chips
                prepend-inner-icon="mdi-magnify"
                @blur="sortRiderStoresBySelectedUid"
                /> 
            </v-col>

              <v-col class="d-flex pl-0 pt-0 pb-0" cols="4" sm="4">
                <v-text-field
                label="Driver phone no."
                v-model="options.filter.phone"
                hide-details
                prepend-inner-icon="mdi-magnify"
                class=""
                clearable
                @keyup.native.enter="getDataFromApi"
                /> 
            </v-col>

              <v-col class="d-flex pl-0 pt-0 pb-0" cols="4" sm="4">

                  <v-select
                  v-model="options.filter.employee_tier_id"
                  :items="op_employee_tier_id"
                  item-text="text"
                  item-value="value"
                  label="Employee Tier"
                  multiple
                  :single-line="true"
                  height="32px"
                  >
                      <template v-slot:prepend-item>
                          <v-list-item
                          ripple
                          @click="toggle_multi_select_emtier"
                          >
                          <v-list-item-action>
                              <v-icon :color="options.filter.employee_tier_id.length > 0 ? 'indigo darken-4' : ''">{{ selectall_emtier_icon }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                              <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                      </template>
                  </v-select>

              </v-col>
              
            <v-col class="d-flex pl-0 pt-0 pb-0" cols="4" sm="4">

                <v-select
                  v-model="options.filter.order_start_type"
                  :items="op_order_start_type"
                  item-text="text"
                  item-value="value"
                  label="Order Start Type"
                  multiple
                  :single-line="true"
                  height="32px"
                  >
                    <template v-slot:prepend-item>
                        <v-list-item
                        ripple
                        @click="toggle_multi_select_orderstart"
                        >
                        <v-list-item-action>
                            <v-icon :color="options.filter.order_start_type.length > 0 ? 'indigo darken-4' : ''">{{ selectall_orderstart_icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
              </v-col>
              <v-col class="d-flex pl-0 pt-0 pb-0" cols="4" sm="4">
                <v-select
                v-model="options.filter.payment_type"
                :items="op_payment_type"
                item-text="text"
                item-value="value"
                label="Payment Type"
                multiple
                :single-line="true"
                height="32px"
                >
                    <template v-slot:prepend-item>
                        <v-list-item
                        ripple
                        @click="toggle_multi_select_paytype"
                        >
                        <v-list-item-action>
                            <v-icon :color="options.filter.payment_type.length > 0 ? 'indigo darken-4' : ''">{{ selectall_paytype_icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>

            </v-col>
              <v-col class="d-flex pl-0 pt-0 pb-0" cols="4" sm="4">
                <v-select
                v-model="options.filter.guarantee"
                :items="op_guarantee"
                item-text="text"
                item-value="value"
                label="Guarantee"
                multiple
                :single-line="true"
                height="32px"
                >
                    <template v-slot:prepend-item>
                        <v-list-item
                        ripple
                        @click="toggle_multi_select_guarantee"
                        >
                        <v-list-item-action>
                            <v-icon :color="options.filter.guarantee.length > 0 ? 'indigo darken-4' : ''">{{ selectall_guarantee_icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                    </template>
                </v-select>
            </v-col>
            <v-col class="d-flex pl-0 pt-0 pb-0" cols="4" sm="4">
              <v-checkbox 
                v-model="options.filter.case" 
                true-value="skip_fixed_order" 
                false-value="" 
                label="Exclude fixed order">
              </v-checkbox>
            </v-col>
            <v-col class="d-flex pl-0 pt-0 pb-0" cols="10" sm="10">
              <date-picker 
                class="mr-3"
                v-model="options.filter.datetime_range"
                type="datetime"
                :time-picker-options="datetimeConfig" 
                format="DD-MM-YYYY HH:mm"  
                value-type="DD-MM-YYYY HH:mm:ss"
                :placeholder="datetimePlaceholder"
                with="100%"
                :disabled-date="checkAllowDate"
                range
                clearable
                confirm>
              </date-picker>
              <v-btn class="mb-1 mx-1" depressed small color="primary" @click="options.page = 1;getTripsTimingReport(options);getTripsTimingSummary(options);" :disabled="!allowSearch">
                  <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <v-btn class="mb-1 mx-1" 
                depressed dark small 
                color="primary" 
                @click="clickExportTripsTimingReportBtn();">
                  <v-icon>mdi-export</v-icon>
              </v-btn>
            </v-col>
            <!--
            <v-col class="d-flex" cols="2" sm="2" v-show="canDo && canDo.can.includes('export')">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="search_date"
                      label="Select export month"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="search_date"
                    type="month"
                    no-title
                    scrollable
                    @change="$refs.menu.save(search_date);"
                  >
                    
                  </v-date-picker>
                </v-menu>
            </v-col>
            -->

            </v-row>
          </v-toolbar>
        </div>
        <v-data-table
        :headers="headers"
        :items="dataLisings"
        item-key="id"
        :options.sync="options"
        :server-items-length="totalResult"
        :loading="loadingDataTable"
        multi-sort
        :footer-props="{
              'items-per-page-options': [25, 100, 500, 1000, 2000]
        }"
        class="text-small"
        >
            <template v-slot:[`item.order_no`]="{item}">

              <v-chip color="#BDFABD" small v-if="item.employee_tier_id == tpc_tier_id && ( item.store_uid != item.employee_store_uid && item.employee_store_uid != null ) && user_type == 'REDBOOK'" label>
                {{ item.order_no }}
              </v-chip>
              <v-chip color="#FFFFFF" small v-if="item.employee_tier_id == tpc_tier_id && ( item.store_uid == item.employee_store_uid ) && user_type == 'REDBOOK'" label>
                {{ item.order_no }}
              </v-chip>
              <v-chip color="#EBBEFD" small v-if="item.employee_tier_id != tpc_tier_id && user_type == 'REDBOOK'" label>
                {{ item.order_no }}
              </v-chip>
              <span v-if="user_type == 'DM'">{{ item.order_no }}</span>
            </template>

            <template v-slot:[`item.employee_name`]="{item}">
              <div v-if="!external_fleet.includes(item.fleet_name)" >
                <router-link v-if="item.employee_id" :to="{ name: 'DriversDetails', params: { id: item.employee_id }}">{{ item.employee_name }}</router-link><br/>
                {{ item.employee_no }}
              </div>
              <div v-if="external_fleet.includes(item.fleet_name)" >
                {{ item.employee_name }}
              </div>
            </template>
            <template v-slot:[`item.distance`]="{item}">
              {{ item.distance | comma }}
              <div v-if="external_fleet.includes(item.fleet_name)">({{ item.partner_distance | comma }})</div>
            </template>
            <template v-slot:[`item.total_amount`]="{value}">
              {{ value | comma }}
            </template>
            <template v-slot:[`item.trip_created_at`]="{value}">
              <span v-if="value">{{ value | datetime}}</span>
            </template>
            <template v-slot:[`item.order_created_at`]="{value}">
              {{ value | datetime}}
            </template>
            <template v-slot:[`item.webhook_updated_at`]="{value}">
              <span v-if="value">{{ value | datetime}}</span>
            </template>

            <template v-slot:[`item.order_type`]="{item}">
              <span v-if="isASAP(item)">ASAP</span>
              <span v-else>Future</span>
            </template>

            <template v-slot:[`item.match_time`]="{item}">
              <span v-if="item.match_time != null">{{ secToMinText(item.match_time)}}</span>
              <span v-else>--</span>
            </template>

            <template v-slot:[`item.travel_time`]="{item}">
              <span v-if="item.travel_time != null">{{ secToMinText(item.travel_time)}}</span>
              <span v-else>--</span>
            </template>

            <template v-slot:[`item.wait_time`]="{item}">
              <span v-if="item.wait_time != null">{{ secToMinText(item.wait_time)}}</span>
              <span v-else>--</span>
            </template>

            <template v-slot:[`item.instore_time`]="{item}">
              <span v-if="item.instore_time != null">{{ secToMinText(item.instore_time)}}</span>
              <span v-else>--</span>
            </template>

            <template v-slot:[`item.drive_time`]="{item}">
              <span v-if="item.drive_time != null">{{ secToMinText(item.drive_time)}}</span>
              <span v-else>--</span>
            </template>

            <template v-slot:[`item.delivery_time`]="{item}">
              <span v-if="item.delivery_time != null">{{ secToMinText(item.delivery_time)}}</span>
              <span v-else>--</span>
            </template>

            <template v-slot:[`item.guarantee`]="{item}">
              <span v-if='item.guarantee != ""'>{{ item.guarantee }}</span>
              <span v-else>--</span>
            </template>

            <template v-slot:[`item.action`]="{item}">
                <v-btn class="ma-1" outlined color="primary" icon tile small>
                  <v-icon small @click="manualOrderTopup(item.order_no)">mdi-wallet-plus</v-icon>
                </v-btn>
            </template>
        </v-data-table>

    </div>
  </div>
</template>
<script>
import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name: 'TripsTimingReport',
    components: {
        pageHeading,
        DatePicker
    },
    data: () => ({
            tpc_tier_id: null,
            user_type: null,
            rb_brands: [],
            rb_stores: null,
            rb_store_array: [],
            rb_store_array_filtered: [],
            filterDatePicker: false,
            editedItem: {
              order_no: '',
              amount: 0,
              transaction_type: null,
              note: '',
              sms: ''
            },
            defaultItem: {
              order_no: '',
              amount: 0,
              transaction_type: null,
              note: '',
              sms: ''
            },
            options: { 
                page: 1,
                itemsPerPage: 25,
                sortBy: ['order_created_at','trip_created_at','trip_name'],
                sortDesc: [true,true,true],
                multiSort: true,
                mustSort: true,
                filter: {
                    trip_name: '',
                    order_no: '',
                    phone: '',
                    ln_status: [],
                    datetime_range: [],
                    date_start: '',
                    date_end: '',
                    case: null,
                    brand_id: [],
                    order_start_type : [],
                    scope_store_rider : null,
                    order_store_uid : null,
                    employee_store_uid : null,
                    scope_store_type : "Order",
                    fleet_name : "ZEEK,ZEEKAGG,MFG,GRAB,PANDAGO,LALAMOVE",
                    payment_type : [],
                    employee_tier_id : [],
                    guarantee: []
                },
            },
            date: null,
            menu: null,
            datetimeConfig:{
                start: '00:00', 
                step:'01:00' , 
                end: '23:00', 
                format: 'HH:mm' 
            },
            search_date: "",
            datetimePlaceholder: '',
            expanded: [],
            isSingleExpand: false,
            external_fleet : ['ZEEKAGG','GRAB','PANDAGO','LALAMOVE'],
            trip_cases : [
              { value: 'ln_manual', text: 'LN Manual Order' }
            ],
            op_fleet_name : [
              { value: 'LOGINEXT', text: 'Loginext' },
              { value: 'ZEEK', text: 'Zeek' },
              { value: 'MFG', text: 'MFG' },
              { value: 'GRAB', text: 'Grab' },
              { value: 'PANDAGO', text: 'Panda Go' }
            ],
            op_order_start_type : [
              { value: 'asap', text: 'ASAP' },
              { value: 'future', text: 'Future' },
            ],
            op_payment_type : [
              { value: 'COD', text: 'COD' },
              { value: 'Prepaid', text: 'Prepaid' },
            ],
            op_employee_tier_id : [ ],
            op_guarantee : [
              { value: '20', text: '20 mins' },
              { value: '30', text: '30 mins' },
              { value : '-1' , text : 'Other' }
            ],
            form_valid : false,
            validate_rules : {
                required: v => !!v || 'This field is required',
                select2: v => {
                  if(typeof v === 'object'){
                    return (v.value !== '') || 'This field is required';
                  } else {
                    return true
                  }
                },
                // select: v => { return (v !== '') || 'This field is required' },
                number: v => /^[0-9]/.test(v) || 'Number only please',
            },
            
    }),
    watch: {
        'options.sortBy': function (newVal, oldVal){
         //to work with changes in someOtherProp
          if(newVal && oldVal && this.dataLisings.length > 0){
            this.getTripsTimingReport(this.options);
          }
        },
        filterDatePicker (val){
          if(val && this.options.filter.date_range.length == 0){
            this.options.filter.date_range = [ moment().subtract(0, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
          }
        }
    },
    filters: {
        trans_status(value) {
            return value == '0' ? "success" : value
        }
    },
    methods: {
        ...mapActions(['genCan','getTripsTimingReport','loadTransTickets','sendOrderManualTopup', 'getAllRedbookStore', 'getRedbookBrand', 'getTPCTierId', 'exportTripsTimingReport', 'getEmployeeTier', 'getTripsTimingSummary']),
        checkAllowDate(date, currentValue) {
          //console.log("currentValue", currentValue);

          if (currentValue.length == 0)
            return false;

          if (moment(currentValue[0]).subtract(32, 'days').isBefore(moment(date)) && moment(currentValue[0]).add(32, 'days').isAfter(moment(date)))
            return false;

          return true;
        },
        clickExportTripsTimingReportBtn() {
          // rollback to use daterange as normal search

          //console.log("search_date", this.search_date);
          //if (this.search_date == "")
          //{
          //  alert("กรุณาเลือกปี และ เดือน สำหรับส่งไฟล์เข้า email");
          //  return false;
          //}
          //let export_options = this.updateDateRangeOption(JSON.parse(JSON.stringify(this.options)));
          //this.exportTripsTimingReport(export_options);
          this.exportTripsTimingReport(this.options);
        },
        prepareStoreData : async function () {
            // console.log("Load redbook store")
            this.rb_store_array = await this.getAllRedbookStore({ load_full : true});
            this.rb_store_array_filtered = await this.getAllRedbookStore( );
            this.rb_brands = (await this.getRedbookBrand()).map( (b) => {
              b.val = { minor_brand_id : b.id }
              return b;
            });

            //add faas brands
            let faas_brand_lists = JSON.parse(Buffer.from(process.env.VUE_APP_FAAS_BRAND_LISTS, 'base64').toString());
            //[ {shortname : "FAAS :: BKFS", val : { faas_brand_code : 'BKFS'} } ]
            for(let i = 0 ; i < faas_brand_lists.length ; i++)
              this.rb_brands.push( faas_brand_lists[i] );

            this.rb_stores = _.keyBy( this.rb_store_array, 'store_uid' );
            this.op_employee_tier_id = _.map(await this.getEmployeeTier(), (tier) => { return { value: tier.id, text: tier.name }; })
        },
        getDataFromApi() {
            this.options.page = 1;
            this.getTripsTimingReport(this.options);
        },
        updateDateRangeOption(option) {
          
          let cur_month = moment(this.search_date + '-15');
          option.filter.datetime_range = [cur_month.startOf('month').toDate(), cur_month.endOf('month').toDate()];
          return option;
        },
        clearFilterDaterange(){
          this.options.filter.date_range = [];
        },
        // loadTransTickets({item}){
        //     // axios.get('http.../' + item.id)
        //     // .then(response => {
        //     //   item.samples = response.data[0]
        //     // })
        //     item.transactions = []
        //     item.tickets = ['test']
        //     console.log(item.transactions, item.tickets)
        // },
        toggle_multi_select () {
          this.$nextTick(() => {
            if (this.selectedAll) {
              this.options.filter.ln_status = []
            } else {
              this.options.filter.ln_status = this.lnHookStatus.slice()
            }
          })
        },
        toggle_multi_select_brand () {
          this.$nextTick(() => {
            if (this.selectedAllBrand) {
              this.options.filter.brand_id = []
            } else {
              this.options.filter.brand_id = _.map(this.rb_brands, "val");
            }
          })
        },
        toggle_multi_select_emtier () {
          this.$nextTick(() => {
            if (this.selectedAllEmtier) {
              this.options.filter.employee_tier_id = []
            } else {
              this.options.filter.employee_tier_id = _.map(this.op_employee_tier_id, "value");
            }
          })
        },
        toggle_multi_select_orderstart () {
          this.$nextTick(() => {
            if (this.selectedAllOrderstart) {
              this.options.filter.order_start_type = []
            } else {
              this.options.filter.order_start_type = _.map(this.op_order_start_type, "value");
            }
          })
        },
        toggle_multi_select_paytype () {
          this.$nextTick(() => {
            if (this.selectedAllPaytype) {
              this.options.filter.payment_type = []
            } else {
              this.options.filter.payment_type = _.map(this.op_payment_type, "value");
            }
          })
        },
        toggle_multi_select_guarantee () {
          this.$nextTick(() => {
            if (this.selectedAllGuarantee) {
              this.options.filter.guarantee = []
            } else {
              this.options.filter.guarantee = _.map(this.op_guarantee, "value")
              // .filter( (v) => { return v != '-1'; });
            }
          })
        },
        save () {
            this.sendOrderManualTopup(this.editedItem);
            this.preview_close()
        },
        initDatetime(force_load = true) {
            var now = moment()
            var roundEnd = now.minute() || now.second() || now.millisecond() ? now.add(1, 'hour').startOf('hour') : now.startOf('hour');
            var roundStart = roundEnd.clone().subtract(2, 'hours');
            console.log(roundStart.toDate(),roundEnd.toDate());
            this.options.filter.datetime_range = [roundStart.toDate(),roundEnd.toDate()];

            //this.options.filter.datetime_range = [moment().startOf('month').toDate(),moment().endOf('month').toDate()];

            this.datetimePlaceholder = roundStart.format('DD-MM-YYYY HH:mm') + ' ~ ' + roundEnd.format('DD-MM-YYYY HH:mm');
            if (force_load)
            {
              this.getTripsTimingReport(this.options);
              this.getTripsTimingSummary(this.options);
            }
        },
        secToMinText (inp_sec) {
          let sec = Math.abs(inp_sec);

          let min_txt = Math.floor(parseFloat(sec) / 60);
          let sec_txt = (sec % 60).toString().padStart(2,"0");
          return (inp_sec < 0 ? "- " : "") + min_txt + "." + sec_txt;
          },
        isASAP(item) {
          //console.log("item.promise_st_time", item.promise_st_time);
          //console.log("item.order_created_at", item.order_created_at);
          
          if(item.promise_st_time) {
            return (moment(item.promise_st_time).subtract(5,'minutes').isSameOrBefore(moment(item.webhook_created_at)))
          }else{
            return true
          }
          
        },
        sortOrderStoresBySelectedUid() {
        const selectedUid = this.options.filter.order_store_uid;
        if(selectedUid?.length){
          this.rb_store_array_filtered.sort((a, b) => {
          const aIncluded = selectedUid.includes(a.store_uid);
          const bIncluded = selectedUid.includes(b.store_uid);
            if (aIncluded && !bIncluded) return -1;
            if (!aIncluded && bIncluded) return 1;
          return 0;
        });
        }
        },
        sortRiderStoresBySelectedUid() {
        const selectedUid = this.options.filter.employee_store_uid;
        if(selectedUid?.length){
          this.rb_store_array.sort((a, b) => {
          const aIncluded = selectedUid.includes(a.store_uid);
          const bIncluded = selectedUid.includes(b.store_uid);
            if (aIncluded && !bIncluded) return -1;
            if (!aIncluded && bIncluded) return 1;
          return 0;
        });
        }
        }
    },
    computed: {
        ...mapGetters(['canDo','tripsTimingReport','loadingDataTable','lnHookStatus','orderTopupTransactionTypes', 'allowSearch', 'loadingDltimeSummary', 'tripsTimingSummary', 'userProfile']),
        headers() {
          var columns = [
                {text: "Order No.", value: 'order_no', width: '150px'},
                //{text: "Fleet", value: 'fleet_name', width: '80px'}, --close on timing
                {text: "Order Store", value: 'store.store_alias_id', width: '80px', sortable: false, align: 'center'},
                {text: "Driver Store", value: 'employee_store.store_alias_id', width: '80px', sortable: false, align: 'center'},
                {text: "Driver Tier", value: 'employee_tier_name', width: '80px', sortable: false, align: 'center'},
                {text: "Driver", value: 'employee_name', width: '130px', sortable: false, align: 'center'},
                {text: "Phone No.", value: 'employee_phone', width: '110px', sortable: false, align: 'center'},
                {text: "Match Time (Mins)", value: 'match_time'},
                {text: "Travel Time (Mins)", value: 'travel_time'},
                {text: "Wait Time (Mins)", value: 'wait_time'},
                {text: "Instore time (Mins)", value: 'instore_time'},
                {text: "Drive Time (Mins)", value: 'drive_time'},
                {text: "Delivery Time (Mins)", value: 'delivery_time'},
                {text: "Payment Type", value: 'payment_method', width: '90px', align: 'center'},
                {text: "Order Status", value: 'latest_ln_order_status' , align: 'center', width: '120px'},
                
                {text: "Distance (M)", value: 'distance', align: 'right' , width: '85px'},
                {text: "Order Type", value: 'order_type' , align: 'center', width: '120px'},

                // {text: "Gross Amount (Bht)", value: 'gross_amount' , align: 'right'},
                // {text: "Discount Amount (Bht)", value: 'discount_amount' , align: 'right'},
                {text: "Order Value (Bht)", value: 'sub_total' , sortable: false ,align: 'right', width: '85px'},
                {text: "Delivery Fee (Bht)", value: 'delivery_fee' , align: 'right', width: '85px'},
                {text: "Trip No.", value: 'trip_name', width: '90px'},
                {text: "Guarantee", value: 'guarantee' , align: 'center', width: '100px'},
                {text: "Order Created", value: 'order_created_at'},
                {text: "Trip Created", value: 'trip_created_at'},
                {text: "Hooked At", value: 'webhook_updated_at'},
                // {text: "Brand", value: 'brand_alias'},
                // {text: "Gross Amount (Bht)", value: 'gross_amount' , align: 'right'},
                // {text: "Discount Amount (Bht)", value: 'discount_amount' , align: 'right'},
                // {text: "Driver Delivery Fee (Bht)", value: 'driver_delivery_fee', align: 'right' },
                // {text: "Driver Getpaid Food (Bht)", value: 'driver_getpaid_food', align: 'right' },
                // {text: "Driver Getpaid Delivery (Bht)", value: 'driver_getpaid_delivery', align: 'right' },
                // {text: "Commission (Bht)", value: 'commission' , align: 'right'},
                // {text: "Topup Food (Bht)", value: 'topup_food', align: 'right' },
                // {text: "Topup Fee (Bht)", value: 'topup_delivery', align: 'right' },
                //{text: "", value: 'action', sortable: false, align: 'center', width: '50px'}, 
            ];
            return columns;
        },
        dataLisings() { 
            var res = this.tripsTimingReport ? this.tripsTimingReport.data : [];
            return  res;
        },
        totalResult(){
            return (this.tripsTimingReport ? this.tripsTimingReport.count : null) || 0;
        },
        filter_date_formatted () {
          if(!this.options.filter.date_range) 
            return '';
            
          var date_format = this.options.filter.date_range.map(x => moment(x).format('DD-MM-YYYY'));
            return typeof date_format == 'object' ? date_format.join(' - ') : '';
        },
        filter_round_time(){
          // if(!this.options.filter.datetime_range) 
            // return '';
            
          // var date_format = this.options.filter.datetime_range.map(x => moment(x).format('DD-MM-YYYY'));
            // return typeof date_format == 'object' ? date_format.join(' - ') : '';
            return [moment().endOf('hour').format('DD-MM-YYYY HH:mm'),moment().endOf('hour').format('DD-MM-YYYY HH:mm')];
        },
        selectedAll () {
            return this.lnHookStatus ? (this.options.filter.ln_status.length === this.lnHookStatus.length) : false;
        },
        selectedAllBrand () {
            return this.rb_brands ? (this.options.filter.brand_id.length === this.rb_brands.length) : false;
        },
        selectedAllEmtier () {
            return this.op_employee_tier_id.length > 0 ? (this.options.filter.employee_tier_id.length === this.op_employee_tier_id.length) : false;
        },
        selectedAllOrderstart () {
            return this.op_order_start_type.length > 0 ? (this.options.filter.order_start_type.length === this.op_order_start_type.length) : false;
        },
        selectedAllPaytype () {
            return this.op_payment_type.length > 0 ? (this.options.filter.payment_type.length === this.op_payment_type.length) : false;
        },
        selectedAllGuarantee () {
            return this.op_guarantee.length > 0 ? (this.options.filter.guarantee.length === this.op_guarantee.length) : false;
        },
        selectedSome () {
            return this.options.filter.ln_status.length > 0 && !this.selectedAll;
        },
        selectedSomeBrand () {
            return this.options.filter.brand_id.length > 0 && !this.selectedAllBrand;
        },
        selectedSomeEmtier () {
            return this.options.filter.employee_tier_id.length > 0 && !this.selectedAllEmtier;
        },
        selectedSomeOrderstart () {
            return this.options.filter.order_start_type.length > 0 && !this.selectedAllOrderstart;
        },
        selectedSomePaytype () {
            return this.options.filter.payment_type.length > 0 && !this.selectedAllPaytype;
        },
        selectedSomeGuarantee () {
            return this.options.filter.guarantee.length > 0 && !this.selectedAllGuarantee;
        },
        selectall_icon () {
            if (this.selectedAll) return 'mdi-close-box'
            if (this.selectedSome) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        selectall_brand_icon () {
            if (this.selectedAllBrand) return 'mdi-close-box'
            if (this.selectedSomeBrand) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        selectall_emtier_icon () {
            if (this.selectedAllEmtier) return 'mdi-close-box'
            if (this.selectedSomeEmtier) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        selectall_orderstart_icon () {
            if (this.selectedAllOrderstart) return 'mdi-close-box'
            if (this.selectedSomeOrderstart) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        selectall_paytype_icon () {
            if (this.selectedAllPaytype) return 'mdi-close-box'
            if (this.selectedSomePaytype) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        selectall_guarantee_icon () {
            if (this.selectedAllGuarantee) return 'mdi-close-box'
            if (this.selectedSomeGuarantee) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    async beforeMount() {
        await this.prepareStoreData();
        this.tpc_tier_id = await this.getTPCTierId();

    },
    mounted () {
        this.genCan();
        this.initDatetime(false);
        this.user_type = localStorage.getItem('auth_type');
    },
    // mounted () {
    // }
};
</script>
<style>
.v-data-table {
  max-width: 100%
}
.v-data-table.text-small td{
    font-size: 11px !important;
    height:40px !important;
}
.expand-info{
    padding: 10px 20px;
}
.expand-info div{
    display: table-row;
}
.expand-info div span{
    display: table-cell;
}
.info-title{
    padding-right: 30px;
}
.info-detail{
    text-align:right;
    vertical-align: middle;
}
.info-detail.success{
    color:#388E3C;
    background:none;
    vertical-align: middle;
}
.custom-table1{
  border-bottom:1px solid #ccc;
}
.custom-table1 th{
  background: #eee;
  height: auto;
  border-bottom: none !important;
}
.v-data-table.text-small .custom-table1 td{
  height: auto !important;
  padding: 3px 20px !important;
  border-bottom:1px solid #ccc;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1px;
}

.grid-container > div {
  text-align: center;
  padding: 3px 4px;
}

.dltime_summary .item_header
{
  font-weight : bold
}

.dltime_summary .item_number
{
  display: flex;
  align-items: center;

}

.dltime_summary .item_number div
{
  width: 100%;
  text-align: right;
}

@media only screen and (max-width: 720px) {
  .header_container {
    flex-direction: column
  }
}
</style>